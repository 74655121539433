/**
 * Component: AccordionModule
 */

export default class AccordionModule {
    constructor() {
        this.$self = $('.js-accordion-module');
        this.$graphic = this.$self.find('.js-accordion-module-graphic');
        this.$items = this.$self.find('.js-accordion-module-item');

        this.init();
    }

    init() {
        this.$items.each((idx, item) => {
            $(item).on('click', function () {
                var allCollapsed = true;

                setTimeout(() => {
                    this.$items.each(function (i, el) {
                        if (!$(el).hasClass('collapsed')) {
                            allCollapsed = false;
                            return;
                        }
                    });
                    if (allCollapsed) {
                        this.$graphic.removeClass('opacity-0');
                    } else {
                        this.$graphic.addClass('opacity-0');
                    }
                }, 300);
            });
        });
    }
}
